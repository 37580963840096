<template>
    <layout>
        <div class="row mb-3 align-items-center">
            <div class="col">
                <PageHeader :title="title" />
            </div>
            <!-- <div class="col-auto">
                <router-link to="/stories"  class="btn btn-theme">
                 <i class="mdi mdi-arrow-left font-size-14 align-middle me-2"></i>
                  Back to Stories List
                </router-link>
            </div> -->
        </div>

        <div class="card">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col">
                        <span class="text-theme" v-if="story.storyGenre == 'fiction'">Fiction</span>
                        <span class="text-theme" v-else>Nonfiction</span>
                        <h2 class="my-1 font-size-24 text-dark-cstm">{{ story.storyTitle }}</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="">
                            <h5 class="font-size-15">Docs Files :</h5>
                            <div class="row gx-2">
                                <template v-if="story.files">
                                    <div class="col-12 mb-2" v-for="file in story.files.split(',')" :key="file.id">
                                        <div class="p-2 mb-3 bg-success bg-soft">
                                            <div class="row gx-2 align-items-center">
                                                <div class="col">
                                                    <p class="mb-0 text-success font-size-14"><strong>File
                                                            name:</strong>
                                                        {{ file.split('01submitit10')[1] }}
                                                    </p>
                                                </div>
                                                <div class="col-auto">
                                                    <div class=""><button class="border-0 me-1 p-0 bg-transparent"
                                                            type="button"><i
                                                                class="mdi mdi-download font-size-20 text-theme-dark"></i></button><button
                                                            class="border-0 p-0 bg-transparent" type="button"><i
                                                                class="mdi mdi-delete font-size-20 text-danger"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-12 mb-2">
                                        <div class="p-2 mb-3 bg-soft">
                                            <div class="row gx-2 align-items-center">
                                                <div class="col">
                                                    <p class="mb-0 text-center text-danger font-size-15"><strong>No
                                                            Files</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="card">
            <div class="card-body">
                <div class="main-search-card ">
                    <div class="mb-3">
                        <div class="row gx-2">
                            <div class="col">
                                <h3 class="my-1 font-size-18 text-dark-cstm">Submissions</h3>
                            </div>
                            <div class="col">
                                <div class="searchBarStyle topBarCstmTable  position-relative">
                                    <input type="text" v-model="searchValueTableSub" class="form-control"
                                        placeholder="Search...">
                                    <span class="iconSearch bx bx-search-alt"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <EasyDataTable :headers="normalHeader"
                    :items="tableItemsSub" :search-value="searchValueTableSub" border-cell theme-color="#3aafa9"
                    :rows-per-page="10" buttons-pagination table-class-name="table-custom-style">
                    <!-- <template #item-journalMain="{ journal }">
                        <div class="text-dark-cstm d-block">
                            {{ journal.jrnlName }}
                        </div>
                    </template> -->
                    <template #item-matchPercent="{ matchPercent }">
                        <!-- <span class="badge badge-soft-success">Match Percent: {{ matchPercent }}%</span> -->
                        <div class="badge badge-soft-success">
                            {{ matchPercent }}%
                        </div>
                    </template>
                    <template #item-status="{ status }">
                        <span class="text-warning" v-if="status == 0">Pending</span>
                        <span class="text-primary" v-if="status == 1">Active</span>
                        <span class="text-danger" v-if="status == 2">Withdrawn</span>
                        <span class="text-danger" v-if="status == 3">Rejected</span>
                        <span class="text-success" v-if="status == 4">Accepted</span>
                    </template>
                    <template #item-created_at="{ created_at }">
                        <span>
                            <i class="mdi mdi-calendar"></i>
                            {{ new
                    Date(created_at).getDate() }} {{ new
                    Date(created_at).toLocaleString('en-US', { month: 'short' }) }}, {{ new
                    Date(created_at).getFullYear() }}
                        </span>
                    </template>
                    <template #item-updated_at="{ created_at, updated_at }">
                        <span v-if="created_at != updated_at">
                            <i class="mdi mdi-calendar"></i>
                            {{ new
                    Date(updated_at).getDate() }} {{ new
                    Date(updated_at).toLocaleString('en-US', { month: 'short' }) }}, {{ new
                    Date(updated_at).getFullYear() }}
                        </span>
                        <span v-else>No Date</span>
                    </template>
                    <!-- <template #item-action="{ id }">
                        <span class="ms-auto d-flex">
                            <button @click="journalEditSts = true" v-on:click="editSubmissionId = id"
                                class="btn btn-sm btn-soft-info">
                                <i class="mdi mdi-pencil"></i>
                            </button>
                        </span>
                    </template> -->
                </EasyDataTable>
            </div>
        </div>

    </layout>
</template>
<script>
    import Layout from "../../router/layouts/main";
    import PageHeader from "@/components/admin/header/page-header";
    import EasyDataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css';
    import axios from "axios";
    import CryptoJS from 'crypto-js';
    // import Select2 from 'vue3-select2-component';

    export default ({
        page: {
            title: "Story Information",
            meta: [
                {
                    name: "description",
                    content: 'description',
                },
            ],
        },
        data() {
            return {
                url: process.env.VUE_APP_URL,
                userType: null,
                journalListRnd: false,
                journalListRndShow: 0,
                journalEditSts: false,
                title: "Story Information",
                preview: [],
                image: [],
                i: 0,
                initialize: false,
                fetchData:false,
                searchValueTable: "",
                searchValueTableSub: "",
                searchValueTableSuggested: '',
                story: [],
                round1: [],
                round2: [],
                round3: [],
                pendingRound1: [],
                pendingRound2: [],
                pendingRound3: [],
                normalHeader: [
                    { text: "Journal Name", value: "jrnlName" },
                    { text: "Match Percent", value: "matchPercent" },
                    { text: "Submission Date", value: "created_at" },
                    { text: "Response Date", value: "updated_at" },
                    { text: "Status", value: "status" }
                ],
                tableItemsSub: [],
                extraServices: [],
                suggestedJournals: [],
                bonusScore: null,
                topJournalId: [],
                journals: [],
                unmutableJournal: [],
                story_id: null,
                superUser: null
            }
        },
        beforeMount() {
            this.userType = atob(decodeURIComponent(JSON.parse(localStorage.getItem('customUser'))))
            if(!this.fetchData){
                this.getSingleStory()
            }
        },
        activated() {
            this.superUser = atob(decodeURIComponent(JSON.parse(localStorage.getItem('customUser'))));
            if(this.fetchData){
                this.getSingleStory()
            }
            // if (this.story_id != null && this.story_id != this.decode(this.$route.params.id)) {
            // }
            // else {
            //     this.getJournalByMatch()
            // }
        },
        watch: {
            $route(to,from) {
                if(from.params.id !== to.params.id && to.name == 'single_storyClient'){
                    this.getSingleStory()
                }
            }
        },
        components: {
            Layout,
            PageHeader,
            EasyDataTable,
            // Select2
        },
        methods: {
            previewImage(event) {
                this.id.push(this.i++)
                var input = event.target;
                if (input.files) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.preview.push(e.target.result);
                    }
                    this.image.push(input.files[0]);
                    reader.readAsDataURL(input.files[0]);
                }
            },
            getSingleStory() {
                let data = new FormData()
                data.append('id', this.decode(this.$route.params.id))
                axios.post(this.url + 'api/getSingleStory', data).then(res => {
                    this.story = res.data.story
                    this.story_id = res.data.story.id
                    this.extraServices = res.data.extraServices
                    this.getDoNotSubmitJournals()
                    this.getStorySubmissionResult()
                    // }
                    this.initialize = true
                })
            },
            getDoNotSubmitJournals() {
                axios.post(this.url + 'api/getDoNotSubmitJournals', { id: this.story.user_id, storyId: this.decode(this.$route.params.id) }).then(res => {
                    this.doNotSubmitList = res.data
                    this.getAllJournals();
                })
            },
            getAllJournals() {
                axios.post(this.url + 'api/getJournalsWithScore').then(response => {
                    let data = new FormData()
                    data.append('id', this.decode(this.$route.params.id))
                    axios.post(this.url + 'api/getStorySubmissionResult', data).then(res => {
                        let submittedJournals = res.data
                        let allJournals = response.data
                        this.unmutableJournal = filterByReference(allJournals, submittedJournals)
                        this.unmutableJournal = this.unmutableJournal.filter((element) => {
                            return !this.doNotSubmitList.map((item) => item.id).includes(element.id);
                        });
                        // this.tableItemsSub = res.data
                        this.tableItemsSub = res.data.map(submission => {
                            return {
                                ...submission,
                                jrnlName: submission.journal.jrnlName
                            };
                        });
                        this.getJournalByMatch();
                        this.fetchData = true

                    })
                })
                const filterByReference = (arr1, arr2) => {
                    let res = [];
                    res = arr1.filter((el) => {
                        return !arr2.find((element) => {
                            if (
                                element.status == 0 ||
                                element.status == 1 ||
                                element.round >= 3
                            ) {
                                return element.journal_id === el.id;
                            }
                        });
                    });
                    return res;
                };
            },
            getJournalByMatch() {
                this.getJournalByAlgorithm()
            },

            
            getStorySubmissionResult() {
                axios.post(this.url + 'api/getStorySubmissionResult', { 'id': this.decode(this.$route.params.id) }).then(res => {
                    // this.tableItemsSub = res.data
                    this.tableItemsSub = res.data.map(submission => {
                            return {
                                ...submission,
                                jrnlName: submission.journal.jrnlName
                            };
                        });
                    this.getJournalByAlgorithm()
                })
            },
            getJournalByAlgorithm() {
                let journals = this.tableItemsSub;
                this.round1 = journals.filter((journal) => journal.round == 1);
                this.round2 = journals.filter((journal) => journal.round == 2);
                this.round3 = journals.filter((journal) => journal.round == 3);
                this.pendingRound1 = journals.filter(
                    (journal) => journal.round == 1 && journal.status == 0
                );
                this.pendingRound2 = journals.filter(
                    (journal) => journal.round == 2 && journal.status == 0
                );
                this.pendingRound3 = journals.filter(
                    (journal) => journal.round == 3 && journal.status == 0
                );
            },
            decode(id) {
                return decodeURIComponent(CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(CryptoJS.enc.Utf8));
            },
            encode(id) {
                return encodeURIComponent(CryptoJS.AES.encrypt(String(id), "Secret Passphrase"));
            }
        }
    })
</script>